body[data-theme="light"] {
    @import '../light-slider.scss';
 
    .slider-tab {
       background-color: #b1a68c;
    }
    
    .title-styles {
      color: white;
   }

   .webicon{
       width: 20%;
       height: auto;
       bottom: 30vh;
       left: 40vw;
   }

   #icons {
       color: white;
   }
   
   #icons:hover{
        
        color: #D7CAAA;
   }

   .header-icon {
      color: #3C342C;
      opacity: 0.8;
   }

   .slider-image {
      border: 5px solid #b1a68c;
   }

   .language {
      background-color: #D7CAAA;
   }

   .link-href {
      color: black;
   }

   .project-date {
      background-color: #5e5749;
      color: white;
   }

   #home {
      h1 {
         color: white;
      }
   }

   .parallax-background {
      background-image: url("sky.jpg");
   }
   

   #about {
    background-image: url("sky2.jpg");
    background-size: auto;
    
      h1 span {
         color: white;
      }
      .polaroid span {
         background:  #D7CAAA;;
      }
      .card {
         background: #D7CAAA;;
         color: black;
      }
   }

   #portfolio {
      background-image: url("sky3.jpg");
      background-size: auto;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .section-title {
         color: white !important;
      }
      .foto div {
         background: #b1a68c;
      }
      .project-title-settings {
         color: black;
      }
   }

   #experience {
      filter: brightness(100%);
      background-image: url("sky5.jpg");
      background-size: cover;

      .section-title {
         color: white !important;
      }

      .experience-badge {
         background: #D7CAAA !important;
         color: black;
      }

      .main-badge {
         background: #D7CAAA !important;
      }

      .vertical-timeline-element-date {
         color: white;
      }

      .vertical-timeline-element-icon {
         background: #D7CAAA !important;
      }
      @media only screen and (max-width: 1169px) { 
         .vertical-timeline-element-date {
            color: black;
         }
      }
   }

   #skills{
      background-image: url("sky4.jpg");
      background-size: cover;
      background-repeat: no-repeat;
   }

   .modal-inside .modal-content {
      background: #D7CAAA;
      color: black;
   }

   .close-icon {
      color: black;
   }
    
}

