body[data-theme="dark"] {  

   @import '../dark-slider.scss';

   .slider-tab {
      background-color: #8c8c8c;
   }
   
   .title-styles {
     color: white;
  }

  .webicon{
   width: 20%;
   height: auto;
   bottom: 30vh;
   left: 40vw;
}

  #icons {
      color: white;
  }
  
  #icons:hover{
       
       color: #8c8c8c;
  }

  .header-icon {
     color: #3C342C;
     opacity: 0.8;
  }

  .slider-image {
     border: 5px solid #8c8c8c;
  }

  .language {
     background-color: #8c8c8c;
  }

  .link-href {
     color: white;
  }

  .project-date {
     background-color: black;
     color: white;
  }

  #home {
     background-size: auto;
     h1 {
        color: white;
     }
  }

  .parallax-background {
   background-image: url("night2.jpg");
  }

  #about {
   background-image: url("night.jpg");
   background-size: auto;
     h1 span {
        color: white;
     }
     .polaroid span {
        background:  #616161;;
     }
     .card {
        background: #616161;;
        color: white;
     }
  }

  #portfolio {
      background-image: url("night5.jpg");
      background-size: auto;
      min-height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
     .section-title {
        color: white !important;
     }
     .foto div {
        background: #616161;
     }
     .project-title-settings {
        color: white;
     }
  }

  #experience {
     filter: brightness(100%);
     background-image: url("night3.jpg");
     background-size: auto;
     
     .section-title {
        color: white !important;
     }

     .experience-badge {
        background: #8c8c8c !important;
        color: white;
     }

     .main-badge {
        background: #8c8c8c !important;
     }

     .vertical-timeline-element-date {
        color: white;
     }

     .vertical-timeline-element-icon {
        background: #8c8c8c !important;
     }
     @media only screen and (max-width: 1169px) { 
        .vertical-timeline-element-date {
           color: black;
        }
     }
  }

  #skills{
   background-image: url("night4.jpg");
   background-size: cover;
   background-repeat: no-repeat;
}

  .modal-inside .modal-content {
     background: #616161;
     color: white;
  }

  .close-icon {
     color: white;
  }
}


#contact {
   background-image: url("night5.jpg");

}