@import './variable-modules.scss';

.pink {
  color: $pink;
}

.green {
  color: $green;
}

.skills {
  columns: 1;
  @media only screen and (min-width: 940px) {
    columns: 2;
  }
  padding-left: 0;

  li {
    list-style-type: none;
    margin: 0;
    line-height: 1.75;

    &:hover {
      transform: translateY(-3px);
      transition: all 250ms ease;
    }
  }
}